@media (max-width: 1199px) {
  .flickr li a {
    width: 62px;
    height: 62px;
  }

  .plazart-megamenu .mega .mega-dropdown-menu {
    width: auto !important;
  }
}

@media (max-width: 991px) {
  .header3,
  .header2 {
    .top-bar {
      .tz-module {
        float: none !important;
        display: inline-block;
        &:after {
          clear: both;
          display: block;
          content: '';
        }
      }
      .container > .row > div {
        text-align: center;
      }
    }
    .tz-headerRight {
      float: none !important;
      display: block;
      text-align: center;
      &:after {
        content: '';
        display: block;
        clear: both;
      }
      .tzheader_social {
        display: inline-block;
        li:first-child {
          padding-left: 0;
        }
      }
    }
  }

  .hikashop_product_page .lightSlider li {
    img {
      display: block;
      margin: auto;
    }
  }

  .single_article {
    padding: 0;
    .tz_info {
      padding-right: 15px;
      padding-top: 30px;
    }
    .tz_image {
      margin: auto;
      display: inline-block;
    }
    .tz_media {
      text-align: center;
    }
    .tz-plazart-list {
      li {
        width: 100% !important;
      }
    }
  }

  .tz-testimonial-style3 .item-testimonial {
    padding: 0;
  }

  .header-menu #plazart-mainnav .navbar-header > .btn-navbar {
    display: block;
    i {
      font-size: 17px;
      color: #bebebe;
    }
  }

  .header1 > .header-menu .plazart-megamenu > ul > li > a {
    color: @crt_title_cl;
  }

  .header-menu {
    .plazart-mainnav {
      .nav-collapse {
        position: absolute;
        left: 0;
        right: 0;
        background: @white;
        max-height: 480px;
        border-top: 3px solid @crt_title_cl;
        z-index: 99999;
      }
    }
    .plazart-megamenu {
      .mega-col-nav {
        padding: 0 !important;
      }
      > ul {
        float: none;
        margin: 0;
        > li > a {
          padding: 8px 15px !important;
          line-height: 24px;
          border-bottom: 1px solid #ededed !important;
        }
      }
    }
    .dropdown-menu {
      border: none !important;
    }
    .custom_animated .plazart-megamenu .dropdown {
      &.open {
        > .dropdown-menu {
          display: block;
        }
      }
      .dropdown-menu {
        .box-shadow(none);
        display: none;
        li > a {
          padding: 8px 15px !important;
          cursor: pointer;
        }
        &.col-xs-12 {
          .mega-col-nav li > a.mega-group-title {
            padding: 8px 15px !important;
          }
          .mega-dropdown-inner {
            padding: 0;
          }
        }
      }
    }
  }

  #hikashop_cart_listing .hikashop_showcart_infos td, #hikashop_cart_listing .hikashop_cart_products td {
    padding: 20px 8px;
  }

  #hikashop_cart_listing .hikashop_showcart_infos th, #hikashop_cart_listing .hikashop_cart_products th {
    font-size: 14px;
    padding: 10px 15px;
  }

  .hikashop_user_registration_page {
    margin-bottom: 30px;
  }

  .tab-style-2 ul li a {
    width: 155px;
    height: 155px;
  }
}

@media (max-width: 991px) and(min-width: 768px) {
  .tz_grid.col-1,
  .tz_grid.col-2 {
    width: 33.33%;
  }

  .tz_grid.row-1.col-2 {
    width: 66.67%;
  }

  .tz_blog .tz_content {
    left: 30px;
    right: 30px;
    bottom: 30px;
  }

  .tz_grid.col-2.row-2 {
    .tz_content > * {
      display: block;
      margin-bottom: 15px;
      &:after {
        content: none;
      }
    }
  }
}

@media (max-width: 767px) {
  //#contact-form input[type='checkbox'] {
  //  left: 15px;
  //}
  .tz_blog .tz_content {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    padding: 30px 20px;
    background: #fafafa;
    * {
      color: @color_666;
    }
    > * {
      display: block;
      margin-bottom: 13px;
      &:after {
        content: none !important;
      }
    }
  }

  .tz_blog_item.tz_grid {
    height: auto !important;
  }

  .tab-style-2 ul li {
    float: none;
    display: block;
    margin-right: 0;
    margin-bottom: 15px;
    border-color: @crt_title_cl;
    &.open, &.active {
      border-color: @crt_global_cl;
      a {
        .tab_span {
          color: @crt_global_cl;
        }
      }
    }
    a {
      display: block;
      width: auto;
      height: auto;
      background: @white !important;
      &:after, &:before {
        content: none;
      }
      .tab_span {
        position: relative;
        display: block;
        padding: 10px 20px;
        color: @crt_title_cl;
      }
    }
  }

  .mod-languages .tz_language {
    padding: 14px 14px 14px 0;
  }

  .tzheader_line {
    padding: 0 10px;
  }

  .tzheader_social {
    text-align: center;
    li {
      padding-left: 23px;
      display: inline-block;
      float: none;
    }
  }

  .tz_desc_for_title_mod {
    padding-left: 0;
    padding-right: 0;
  }

  .tz-module.module.style1 {
    .module-title {
      font-size: 34px;
    }
  }

  .tz-testimonials {
    padding: 0;
    position: relative;
    bottom: 0;
  }

  .hikashop_products_listing.layout_list .hikashop_product .tz_shop_item_info,
  .hikashop_products_listing.layout_list .hikashop_product .hikashop_product_image {
    display: block;
    width: 100%;
    max-width: none;
  }

  .filter.pull-left {
    float: none !important;
    text-align: center;
  }

  .hikashop_products_listing_main .tz_top .hikashop_results_counter {
    margin: 20px 0 0;
    text-align: center;
    float: none !important;
    display: block;
  }

  .hikashop_products_listing_main .tz_top .view_list {
    margin: 20px 0 0;
    text-align: center;
    float: none !important;
  }

  .k2SearchBlock .tz_icon_search,
  .hikashop_cart_module .tz_icon_cart {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .header-menu #plazart-mainnav .navbar-header > .btn-navbar {
    padding: 30px 15px 30px 0;
  }

  .header-menu .is-logo-sl {
    padding: 28px 0;
  }

  .hikashop_cart_module .hikashop_cart {
    min-width: 320px;
  }

  .tz_features .tz_feature {
    padding: 30px;
  }

  .tz_service_grid .tz_image img {
    height: 100%;
  }

  .contact {
    padding: 30px 15px;
  }

  #hikashop_cart_listing .hikashop_showcart_infos th, #hikashop_cart_listing .hikashop_cart_products {
    tr, td, th {
      display: block;
      text-align: center !important;
    }
    th {
      font-size: 12px;
      padding: 10px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      &.hikashop_cart_image_title,
      &.hikashop_cart_delete_title {
        padding: 0;
      }
    }
    th, td {
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: none;
      &:last-child {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
    tr.hikashop_show_cart {
      padding: 15px 0;
      td {

      }
    }
  }

  #hikashop_cart_listing .hikashop_cart_button {
    padding: 10px 20px;
    margin-bottom: 10px;
  }

}

@media (max-width: 480px) {
  .hikashop_products_listing.layout_list .hikashop_product .add_to_cart_listing .hikashop_cart_button {
    margin-bottom: 10px;
  }

  .header1 > .header-menu {
    padding: 0;
  }

  .tab-style .nav-tabs li a {
    padding: 10px 40px;
    margin: 0;
    &:after {
      display: block;
      position: relative;
      right: 0;
      padding: 20px 0 0;
    }
  }

  .tz-testimonial .item-testimonial {
    padding: 0;
  }

  .tz_blog_item.tz_grid {
    display: block;
    width: 100% !important;
    //height: 300px !important;
  }

  .custom_text_top_footer {
    > p {
      margin-bottom: 20px;
    }
    .desc {
      font-size: 30px;
    }
  }

  .k2_rooms .owl-item.center {
    .scale(1);
  }

  .tzFooterSocial,
  .tzCopyright {
    text-align: center;
    display: block;
    float: none !important;
    ul {
      li {
        display: inline-block;
        float: none !important;
      }
    }
  }

  .tzCopyright {
    padding-bottom: 15px;
  }

  .tzFooterSocial.style-2 {
    padding-top: 15px;
  }

  .tz-testimonial-style2 .tz_testimonial_desc {
    padding: 0 15px;
  }

  .hikashop_product_page #hikashop_product_right_part .hikashop_product_quantity_div {
    display: block;
    margin-bottom: 10px;
  }

  .hikashop_product_page #hikashop_product_right_part .hikashop_add_wishlist,
  .hikashop_product_page #hikashop_product_right_part .hikashop_product_quantity_div.hikashop_product_quantity_add_to_cart_div {
    display: inline-block;
    margin-bottom: 10px;
  }

  .hikashop_product_page #hikashop_product_right_part .hikashop_product_quantity_div input {
    width: 100% !important;
  }

  .hikashop_product_page .hikashop_product_bottom_part.show_tabular .hikashop_tabs_ul li a {
    padding: 10px;
  }

  .hikashop_product_page .hikashop_product_bottom_part.show_tabular .hikashop_tabs_content {
    padding: 20px;
  }

  .hikashop_product_page #hikashop_comment_form .hika_comment_listing {
    padding: 10px 0;
  }
}

@media (max-width: 320px) {

}