@font-face {
  font-family: 'et-line';
  src: url('../../../fonts/EtLine/fonts/et-line.eot');
  src: url('../../../fonts/EtLine/fonts/et-line.eot?#iefix') format('embedded-opentype'),
  url('../../../fonts/EtLine/fonts/et-line.woff') format('woff'),
  url('../../../fonts/EtLine/fonts/et-line.ttf') format('truetype'),
  url('../../../fonts/EtLine/fonts/et-line.svg#et-line') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Use the following CSS code if you want to use data attributes for inserting your icons */
[data-icon-et]:before {
  font-family: 'et-line';
  content: attr(data-icon-et);
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
}

/* Use the following CSS code if you want to have a class per icon */
/*
Instead of a list of all class selectors,
you can use the generic selector below, but it's slower:
[class*="icon-"] {
*/
.et-icon-mobile, .et-icon-laptop, .et-icon-desktop, .et-icon-tablet, .et-icon-phone, .et-icon-document,
.et-icon-documents, .et-icon-search, .et-icon-clipboard, .et-icon-newspaper, .et-icon-notebook, .et-icon-book-open,
.et-icon-browser, .et-icon-calendar, .et-icon-presentation, .et-icon-picture, .et-icon-pictures, .et-icon-video,
.et-icon-camera, .et-icon-printer, .et-icon-toolbox, .et-icon-briefcase, .et-icon-wallet, .et-icon-gift,
.et-icon-bargraph, .et-icon-grid, .et-icon-expand, .et-icon-focus, .et-icon-edit, .et-icon-adjustments,
.et-icon-ribbon, .et-icon-hourglass, .et-icon-lock, .et-icon-megaphone, .et-icon-shield, .et-icon-trophy,
.et-icon-flag, .et-icon-map, .et-icon-puzzle, .et-icon-basket, .et-icon-envelope, .et-icon-streetsign,
.et-icon-telescope, .et-icon-gears, .et-icon-key, .et-icon-paperclip, .et-icon-attachment, .et-icon-pricetags,
.et-icon-lightbulb, .et-icon-layers, .et-icon-pencil, .et-icon-tools, .et-icon-tools-2, .et-icon-scissors,
.et-icon-paintbrush, .et-icon-magnifying-glass, .et-icon-circle-compass, .et-icon-linegraph, .et-icon-mic, .et-icon-strategy,
.et-icon-beaker, .et-icon-caution, .et-icon-recycle, .et-icon-anchor, .et-icon-profile-male, .et-icon-profile-female,
.et-icon-bike, .et-icon-wine, .et-icon-hotairballoon, .et-icon-globe, .et-icon-genius, .et-icon-map-pin,
.et-icon-dial, .et-icon-chat, .et-icon-heart, .et-icon-cloud, .et-icon-upload, .et-icon-download,
.et-icon-target, .et-icon-hazardous, .et-icon-piechart, .et-icon-speedometer, .et-icon-global, .et-icon-compass,
.et-icon-lifesaver, .et-icon-clock, .et-icon-aperture, .et-icon-quote, .et-icon-scope, .et-icon-alarmclock,
.et-icon-refresh, .et-icon-happy, .et-icon-sad, .et-icon-facebook, .et-icon-twitter, .et-icon-googleplus,
.et-icon-rss, .et-icon-tumblr, .et-icon-linkedin, .et-icon-dribbble {
  font-family: 'et-line';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
}

.et-icon-mobile:before {
  content: "\e000";
}

.et-icon-laptop:before {
  content: "\e001";
}

.et-icon-desktop:before {
  content: "\e002";
}

.et-icon-tablet:before {
  content: "\e003";
}

.et-icon-phone:before {
  content: "\e004";
}

.et-icon-document:before {
  content: "\e005";
}

.et-icon-documents:before {
  content: "\e006";
}

.et-icon-search:before {
  content: "\e007";
}

.et-icon-clipboard:before {
  content: "\e008";
}

.et-icon-newspaper:before {
  content: "\e009";
}

.et-icon-notebook:before {
  content: "\e00a";
}

.et-icon-book-open:before {
  content: "\e00b";
}

.et-icon-browser:before {
  content: "\e00c";
}

.et-icon-calendar:before {
  content: "\e00d";
}

.et-icon-presentation:before {
  content: "\e00e";
}

.et-icon-picture:before {
  content: "\e00f";
}

.et-icon-pictures:before {
  content: "\e010";
}

.et-icon-video:before {
  content: "\e011";
}

.et-icon-camera:before {
  content: "\e012";
}

.et-icon-printer:before {
  content: "\e013";
}

.et-icon-toolbox:before {
  content: "\e014";
}

.et-icon-briefcase:before {
  content: "\e015";
}

.et-icon-wallet:before {
  content: "\e016";
}

.et-icon-gift:before {
  content: "\e017";
}

.et-icon-bargraph:before {
  content: "\e018";
}

.et-icon-grid:before {
  content: "\e019";
}

.et-icon-expand:before {
  content: "\e01a";
}

.et-icon-focus:before {
  content: "\e01b";
}

.et-icon-edit:before {
  content: "\e01c";
}

.et-icon-adjustments:before {
  content: "\e01d";
}

.et-icon-ribbon:before {
  content: "\e01e";
}

.et-icon-hourglass:before {
  content: "\e01f";
}

.et-icon-lock:before {
  content: "\e020";
}

.et-icon-megaphone:before {
  content: "\e021";
}

.et-icon-shield:before {
  content: "\e022";
}

.et-icon-trophy:before {
  content: "\e023";
}

.et-icon-flag:before {
  content: "\e024";
}

.et-icon-map:before {
  content: "\e025";
}

.et-icon-puzzle:before {
  content: "\e026";
}

.et-icon-basket:before {
  content: "\e027";
}

.et-icon-envelope:before {
  content: "\e028";
}

.et-icon-streetsign:before {
  content: "\e029";
}

.et-icon-telescope:before {
  content: "\e02a";
}

.et-icon-gears:before {
  content: "\e02b";
}

.et-icon-key:before {
  content: "\e02c";
}

.et-icon-paperclip:before {
  content: "\e02d";
}

.et-icon-attachment:before {
  content: "\e02e";
}

.et-icon-pricetags:before {
  content: "\e02f";
}

.et-icon-lightbulb:before {
  content: "\e030";
}

.et-icon-layers:before {
  content: "\e031";
}

.et-icon-pencil:before {
  content: "\e032";
}

.et-icon-tools:before {
  content: "\e033";
}

.et-icon-tools-2:before {
  content: "\e034";
}

.et-icon-scissors:before {
  content: "\e035";
}

.et-icon-paintbrush:before {
  content: "\e036";
}

.et-icon-magnifying-glass:before {
  content: "\e037";
}

.et-icon-circle-compass:before {
  content: "\e038";
}

.et-icon-linegraph:before {
  content: "\e039";
}

.et-icon-mic:before {
  content: "\e03a";
}

.et-icon-strategy:before {
  content: "\e03b";
}

.et-icon-beaker:before {
  content: "\e03c";
}

.et-icon-caution:before {
  content: "\e03d";
}

.et-icon-recycle:before {
  content: "\e03e";
}

.et-icon-anchor:before {
  content: "\e03f";
}

.et-icon-profile-male:before {
  content: "\e040";
}

.et-icon-profile-female:before {
  content: "\e041";
}

.et-icon-bike:before {
  content: "\e042";
}

.et-icon-wine:before {
  content: "\e043";
}

.et-icon-hotairballoon:before {
  content: "\e044";
}

.et-icon-globe:before {
  content: "\e045";
}

.et-icon-genius:before {
  content: "\e046";
}

.et-icon-map-pin:before {
  content: "\e047";
}

.et-icon-dial:before {
  content: "\e048";
}

.et-icon-chat:before {
  content: "\e049";
}

.et-icon-heart:before {
  content: "\e04a";
}

.et-icon-cloud:before {
  content: "\e04b";
}

.et-icon-upload:before {
  content: "\e04c";
}

.et-icon-download:before {
  content: "\e04d";
}

.et-icon-target:before {
  content: "\e04e";
}

.et-icon-hazardous:before {
  content: "\e04f";
}

.et-icon-piechart:before {
  content: "\e050";
}

.et-icon-speedometer:before {
  content: "\e051";
}

.et-icon-global:before {
  content: "\e052";
}

.et-icon-compass:before {
  content: "\e053";
}

.et-icon-lifesaver:before {
  content: "\e054";
}

.et-icon-clock:before {
  content: "\e055";
}

.et-icon-aperture:before {
  content: "\e056";
}

.et-icon-quote:before {
  content: "\e057";
}

.et-icon-scope:before {
  content: "\e058";
}

.et-icon-alarmclock:before {
  content: "\e059";
}

.et-icon-refresh:before {
  content: "\e05a";
}

.et-icon-happy:before {
  content: "\e05b";
}

.et-icon-sad:before {
  content: "\e05c";
}

.et-icon-facebook:before {
  content: "\e05d";
}

.et-icon-twitter:before {
  content: "\e05e";
}

.et-icon-googleplus:before {
  content: "\e05f";
}

.et-icon-rss:before {
  content: "\e060";
}

.et-icon-tumblr:before {
  content: "\e061";
}

.et-icon-linkedin:before {
  content: "\e062";
}

.et-icon-dribbble:before {
  content: "\e063";
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@charset "UTF-8";

@font-face {
  font-family: "furniture";
  src: url("../../../fonts/FurnitureIcons/fonts/furniture.eot");
  src: url("../../../fonts/FurnitureIcons/fonts/furniture.eot?#iefix") format("embedded-opentype"),
  url("../../../fonts/FurnitureIcons/fonts/furniture.woff") format("woff"),
  url("../../../fonts/FurnitureIcons/fonts/furniture.ttf") format("truetype"),
  url("../../../fonts/FurnitureIcons/fonts/furniture.svg#furniture") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon-furn]:before {
  font-family: "furniture";
  content: attr(data-icon-furn);
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="furn-icon-"]:before,
[class*=" furn-icon-"]:before {
  font-family: "furniture" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.furn-icon-001:before {
  content: "a";
}

.furn-icon-014:before {
  content: "b";
}

.furn-icon-027:before {
  content: "c";
}

.furn-icon-040:before {
  content: "d";
}

.furn-icon-041:before {
  content: "e";
}

.furn-icon-028:before {
  content: "f";
}

.furn-icon-015:before {
  content: "g";
}

.furn-icon-002:before {
  content: "h";
}

.furn-icon-003:before {
  content: "i";
}

.furn-icon-016:before {
  content: "j";
}

.furn-icon-029:before {
  content: "k";
}

.furn-icon-042:before {
  content: "l";
}

.furn-icon-043:before {
  content: "m";
}

.furn-icon-030:before {
  content: "n";
}

.furn-icon-017:before {
  content: "o";
}

.furn-icon-004:before {
  content: "p";
}

.furn-icon-005:before {
  content: "q";
}

.furn-icon-018:before {
  content: "r";
}

.furn-icon-031:before {
  content: "s";
}

.furn-icon-044:before {
  content: "t";
}

.furn-icon-045:before {
  content: "u";
}

.furn-icon-032:before {
  content: "v";
}

.furn-icon-019:before {
  content: "w";
}

.furn-icon-006:before {
  content: "x";
}

.furn-icon-007:before {
  content: "y";
}

.furn-icon-020:before {
  content: "z";
}

.furn-icon-033:before {
  content: "A";
}

.furn-icon-046:before {
  content: "B";
}

.furn-icon-047:before {
  content: "C";
}

.furn-icon-034:before {
  content: "D";
}

.furn-icon-021:before {
  content: "E";
}

.furn-icon-008:before {
  content: "F";
}

.furn-icon-009:before {
  content: "G";
}

.furn-icon-022:before {
  content: "H";
}

.furn-icon-035:before {
  content: "I";
}

.furn-icon-048:before {
  content: "J";
}

.furn-icon-049:before {
  content: "K";
}

.furn-icon-036:before {
  content: "L";
}

.furn-icon-023:before {
  content: "M";
}

.furn-icon-010:before {
  content: "N";
}

.furn-icon-011:before {
  content: "O";
}

.furn-icon-024:before {
  content: "P";
}

.furn-icon-037:before {
  content: "Q";
}

.furn-icon-050:before {
  content: "R";
}

.furn-icon-051:before {
  content: "S";
}

.furn-icon-038:before {
  content: "T";
}

.furn-icon-025:before {
  content: "U";
}

.furn-icon-012:before {
  content: "V";
}

.furn-icon-013:before {
  content: "W";
}

.furn-icon-026:before {
  content: "X";
}

.furn-icon-039:before {
  content: "Y";
}

.furn-icon-052:before {
  content: "Z";
}

.furn-icon-065:before {
  content: "0";
}

.furn-icon-064:before {
  content: "1";
}

.furn-icon-063:before {
  content: "2";
}

.furn-icon-062:before {
  content: "3";
}

.furn-icon-061:before {
  content: "4";
}

.furn-icon-060:before {
  content: "5";
}

.furn-icon-059:before {
  content: "6";
}

.furn-icon-058:before {
  content: "7";
}

.furn-icon-057:before {
  content: "8";
}

.furn-icon-056:before {
  content: "9";
}

.furn-icon-055:before {
  content: "!";
}

.furn-icon-054:before {
  content: "\"";
}

.furn-icon-053:before {
  content: "#";
}

.furn-icon-066:before {
  content: "$";
}

.furn-icon-079:before {
  content: "%";
}

.furn-icon-092:before {
  content: "&";
}

.furn-icon-093:before {
  content: "'";
}

.furn-icon-080:before {
  content: "(";
}

.furn-icon-067:before {
  content: ")";
}

.furn-icon-068:before {
  content: "*";
}

.furn-icon-081:before {
  content: "+";
}

.furn-icon-094:before {
  content: ",";
}

.furn-icon-095:before {
  content: "-";
}

.furn-icon-082:before {
  content: ".";
}

.furn-icon-069:before {
  content: "/";
}

.furn-icon-070:before {
  content: ":";
}

.furn-icon-083:before {
  content: ";";
}

.furn-icon-096:before {
  content: "<";
}

.furn-icon-097:before {
  content: "=";
}

.furn-icon-084:before {
  content: ">";
}

.furn-icon-071:before {
  content: "?";
}

.furn-icon-073:before {
  content: "@";
}

.furn-icon-072:before {
  content: "[";
}

.furn-icon-085:before {
  content: "]";
}

.furn-icon-098:before {
  content: "^";
}

.furn-icon-099:before {
  content: "_";
}

.furn-icon-086:before {
  content: "`";
}

.furn-icon-087:before {
  content: "{";
}

.furn-icon-100:before {
  content: "|";
}

.furn-icon-075:before {
  content: "}";
}

.furn-icon-074:before {
  content: "~";
}

.furn-icon-076:before {
  content: "\\";
}

.furn-icon-089:before {
  content: "\e000";
}

.furn-icon-088:before {
  content: "\e001";
}

.furn-icon-090:before {
  content: "\e002";
}

.furn-icon-077:before {
  content: "\e003";
}

.furn-icon-078:before {
  content: "\e004";
}

.furn-icon-091:before {
  content: "\e005";
}

/*---------------- Elegant Icon ----------------*/
@font-face {
  font-family: 'ElegantIcons';
  src: url('../../../fonts/ElegantIcons/ElegantIcons.eot');
  src: url('../../../fonts/ElegantIcons/ElegantIcons.eot?#iefix') format('embedded-opentype'),
  url('../../../fonts/ElegantIcons//ElegantIcons.woff') format('woff'),
  url('../../../fonts/ElegantIcons/ElegantIcons.ttf') format('truetype'),
  url('../../../fonts/ElegantIcons/ElegantIcons.svg#ElegantIcons') format('svg');
  font-weight: normal;
  font-style: normal;
}

.elg{
  font-family: 'ElegantIcons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

.elg_arrow_up:before {
  content: "\21";
}

.elg_arrow_down:before {
  content: "\22";
}

.elg_arrow_left:before {
  content: "\23";
}

.elg_arrow_right:before {
  content: "\24";
}

.elg_arrow_left-up:before {
  content: "\25";
}

.elg_arrow_right-up:before {
  content: "\26";
}

.elg_arrow_right-down:before {
  content: "\27";
}

.elg_arrow_left-down:before {
  content: "\28";
}

.elg_arrow-up-down:before {
  content: "\29";
}

.elg_arrow_up-down_alt:before {
  content: "\2a";
}

.elg_arrow_left-right_alt:before {
  content: "\2b";
}

.elg_arrow_left-right:before {
  content: "\2c";
}

.elg_arrow_expand_alt2:before {
  content: "\2d";
}

.elg_arrow_expand_alt:before {
  content: "\2e";
}

.elg_arrow_condense:before {
  content: "\2f";
}

.elg_arrow_expand:before {
  content: "\30";
}

.elg_arrow_move:before {
  content: "\31";
}

.elg_arrow_carrot-up:before {
  content: "\32";
}

.elg_arrow_carrot-down:before {
  content: "\33";
}

.elg_arrow_carrot-left:before {
  content: "\34";
}

.elg_arrow_carrot-right:before {
  content: "\35";
}

.elg_arrow_carrot-2up:before {
  content: "\36";
}

.elg_arrow_carrot-2down:before {
  content: "\37";
}

.elg_arrow_carrot-2left:before {
  content: "\38";
}

.elg_arrow_carrot-2right:before {
  content: "\39";
}

.elg_arrow_carrot-up_alt2:before {
  content: "\3a";
}

.elg_arrow_carrot-down_alt2:before {
  content: "\3b";
}

.elg_arrow_carrot-left_alt2:before {
  content: "\3c";
}

.elg_arrow_carrot-right_alt2:before {
  content: "\3d";
}

.elg_arrow_carrot-2up_alt2:before {
  content: "\3e";
}

.elg_arrow_carrot-2down_alt2:before {
  content: "\3f";
}

.elg_arrow_carrot-2left_alt2:before {
  content: "\40";
}

.elg_arrow_carrot-2right_alt2:before {
  content: "\41";
}

.elg_arrow_triangle-up:before {
  content: "\42";
}

.elg_arrow_triangle-down:before {
  content: "\43";
}

.elg_arrow_triangle-left:before {
  content: "\44";
}

.elg_arrow_triangle-right:before {
  content: "\45";
}

.elg_arrow_triangle-up_alt2:before {
  content: "\46";
}

.elg_arrow_triangle-down_alt2:before {
  content: "\47";
}

.elg_arrow_triangle-left_alt2:before {
  content: "\48";
}

.elg_arrow_triangle-right_alt2:before {
  content: "\49";
}

.elg_arrow_back:before {
  content: "\4a";
}

.elg_icon_minus-06:before {
  content: "\4b";
}

.elg_icon_plus:before {
  content: "\4c";
}

.elg_icon_close:before {
  content: "\4d";
}

.elg_icon_check:before {
  content: "\4e";
}

.elg_icon_minus_alt2:before {
  content: "\4f";
}

.elg_icon_plus_alt2:before {
  content: "\50";
}

.elg_icon_close_alt2:before {
  content: "\51";
}

.elg_icon_check_alt2:before {
  content: "\52";
}

.elg_icon_zoom-out_alt:before {
  content: "\53";
}

.elg_icon_zoom-in_alt:before {
  content: "\54";
}

.elg_icon_search:before {
  content: "\55";
}

.elg_icon_box-empty:before {
  content: "\56";
}

.elg_icon_box-selected:before {
  content: "\57";
}

.elg_icon_minus-box:before {
  content: "\58";
}

.elg_icon_plus-box:before {
  content: "\59";
}

.elg_icon_box-checked:before {
  content: "\5a";
}

.elg_icon_circle-empty:before {
  content: "\5b";
}

.elg_icon_circle-slelected:before {
  content: "\5c";
}

.elg_icon_stop_alt2:before {
  content: "\5d";
}

.elg_icon_stop:before {
  content: "\5e";
}

.elg_icon_pause_alt2:before {
  content: "\5f";
}

.elg_icon_pause:before {
  content: "\60";
}

.elg_icon_menu:before {
  content: "\61";
}

.elg_icon_menu-square_alt2:before {
  content: "\62";
}

.elg_icon_menu-circle_alt2:before {
  content: "\63";
}

.elg_icon_ul:before {
  content: "\64";
}

.elg_icon_ol:before {
  content: "\65";
}

.elg_icon_adjust-horiz:before {
  content: "\66";
}

.elg_icon_adjust-vert:before {
  content: "\67";
}

.elg_icon_document_alt:before {
  content: "\68";
}

.elg_icon_documents_alt:before {
  content: "\69";
}

.elg_icon_pencil:before {
  content: "\6a";
}

.elg_icon_pencil-edit_alt:before {
  content: "\6b";
}

.elg_icon_pencil-edit:before {
  content: "\6c";
}

.elg_icon_folder-alt:before {
  content: "\6d";
}

.elg_icon_folder-open_alt:before {
  content: "\6e";
}

.elg_icon_folder-add_alt:before {
  content: "\6f";
}

.elg_icon_info_alt:before {
  content: "\70";
}

.elg_icon_error-oct_alt:before {
  content: "\71";
}

.elg_icon_error-circle_alt:before {
  content: "\72";
}

.elg_icon_error-triangle_alt:before {
  content: "\73";
}

.elg_icon_question_alt2:before {
  content: "\74";
}

.elg_icon_question:before {
  content: "\75";
}

.elg_icon_comment_alt:before {
  content: "\76";
}

.elg_icon_chat_alt:before {
  content: "\77";
}

.elg_icon_vol-mute_alt:before {
  content: "\78";
}

.elg_icon_volume-low_alt:before {
  content: "\79";
}

.elg_icon_volume-high_alt:before {
  content: "\7a";
}

.elg_icon_quotations:before {
  content: "\7b";
}

.elg_icon_quotations_alt2:before {
  content: "\7c";
}

.elg_icon_clock_alt:before {
  content: "\7d";
}

.elg_icon_lock_alt:before {
  content: "\7e";
}

.elg_icon_lock-open_alt:before {
  content: "\e000";
}

.elg_icon_key_alt:before {
  content: "\e001";
}

.elg_icon_cloud_alt:before {
  content: "\e002";
}

.elg_icon_cloud-upload_alt:before {
  content: "\e003";
}

.elg_icon_cloud-download_alt:before {
  content: "\e004";
}

.elg_icon_image:before {
  content: "\e005";
}

.elg_icon_images:before {
  content: "\e006";
}

.elg_icon_lightbulb_alt:before {
  content: "\e007";
}

.elg_icon_gift_alt:before {
  content: "\e008";
}

.elg_icon_house_alt:before {
  content: "\e009";
}

.elg_icon_genius:before {
  content: "\e00a";
}

.elg_icon_mobile:before {
  content: "\e00b";
}

.elg_icon_tablet:before {
  content: "\e00c";
}

.elg_icon_laptop:before {
  content: "\e00d";
}

.elg_icon_desktop:before {
  content: "\e00e";
}

.elg_icon_camera_alt:before {
  content: "\e00f";
}

.elg_icon_mail_alt:before {
  content: "\e010";
}

.elg_icon_cone_alt:before {
  content: "\e011";
}

.elg_icon_ribbon_alt:before {
  content: "\e012";
}

.elg_icon_bag_alt:before {
  content: "\e013";
}

.elg_icon_creditcard:before {
  content: "\e014";
}

.elg_icon_cart_alt:before {
  content: "\e015";
}

.elg_icon_paperclip:before {
  content: "\e016";
}

.elg_icon_tag_alt:before {
  content: "\e017";
}

.elg_icon_tags_alt:before {
  content: "\e018";
}

.elg_icon_trash_alt:before {
  content: "\e019";
}

.elg_icon_cursor_alt:before {
  content: "\e01a";
}

.elg_icon_mic_alt:before {
  content: "\e01b";
}

.elg_icon_compass_alt:before {
  content: "\e01c";
}

.elg_icon_pin_alt:before {
  content: "\e01d";
}

.elg_icon_pushpin_alt:before {
  content: "\e01e";
}

.elg_icon_map_alt:before {
  content: "\e01f";
}

.elg_icon_drawer_alt:before {
  content: "\e020";
}

.elg_icon_toolbox_alt:before {
  content: "\e021";
}

.elg_icon_book_alt:before {
  content: "\e022";
}

.elg_icon_calendar:before {
  content: "\e023";
}

.elg_icon_film:before {
  content: "\e024";
}

.elg_icon_table:before {
  content: "\e025";
}

.elg_icon_contacts_alt:before {
  content: "\e026";
}

.elg_icon_headphones:before {
  content: "\e027";
}

.elg_icon_lifesaver:before {
  content: "\e028";
}

.elg_icon_piechart:before {
  content: "\e029";
}

.elg_icon_refresh:before {
  content: "\e02a";
}

.elg_icon_link_alt:before {
  content: "\e02b";
}

.elg_icon_link:before {
  content: "\e02c";
}

.elg_icon_loading:before {
  content: "\e02d";
}

.elg_icon_blocked:before {
  content: "\e02e";
}

.elg_icon_archive_alt:before {
  content: "\e02f";
}

.elg_icon_heart_alt:before {
  content: "\e030";
}

.elg_icon_star_alt:before {
  content: "\e031";
}

.elg_icon_star-half_alt:before {
  content: "\e032";
}

.elg_icon_star:before {
  content: "\e033";
}

.elg_icon_star-half:before {
  content: "\e034";
}

.elg_icon_tools:before {
  content: "\e035";
}

.elg_icon_tool:before {
  content: "\e036";
}

.elg_icon_cog:before {
  content: "\e037";
}

.elg_icon_cogs:before {
  content: "\e038";
}

.elg_arrow_up_alt:before {
  content: "\e039";
}

.elg_arrow_down_alt:before {
  content: "\e03a";
}

.elg_arrow_left_alt:before {
  content: "\e03b";
}

.elg_arrow_right_alt:before {
  content: "\e03c";
}

.elg_arrow_left-up_alt:before {
  content: "\e03d";
}

.elg_arrow_right-up_alt:before {
  content: "\e03e";
}

.elg_arrow_right-down_alt:before {
  content: "\e03f";
}

.elg_arrow_left-down_alt:before {
  content: "\e040";
}

.elg_arrow_condense_alt:before {
  content: "\e041";
}

.elg_arrow_expand_alt3:before {
  content: "\e042";
}

.elg_arrow_carrot_up_alt:before {
  content: "\e043";
}

.elg_arrow_carrot-down_alt:before {
  content: "\e044";
}

.elg_arrow_carrot-left_alt:before {
  content: "\e045";
}

.elg_arrow_carrot-right_alt:before {
  content: "\e046";
}

.elg_arrow_carrot-2up_alt:before {
  content: "\e047";
}

.elg_arrow_carrot-2dwnn_alt:before {
  content: "\e048";
}

.elg_arrow_carrot-2left_alt:before {
  content: "\e049";
}

.elg_arrow_carrot-2right_alt:before {
  content: "\e04a";
}

.elg_arrow_triangle-up_alt:before {
  content: "\e04b";
}

.elg_arrow_triangle-down_alt:before {
  content: "\e04c";
}

.elg_arrow_triangle-left_alt:before {
  content: "\e04d";
}

.elg_arrow_triangle-right_alt:before {
  content: "\e04e";
}

.elg_icon_minus_alt:before {
  content: "\e04f";
}

.elg_icon_plus_alt:before {
  content: "\e050";
}

.elg_icon_close_alt:before {
  content: "\e051";
}

.elg_icon_check_alt:before {
  content: "\e052";
}

.elg_icon_zoom-out:before {
  content: "\e053";
}

.elg_icon_zoom-in:before {
  content: "\e054";
}

.elg_icon_stop_alt:before {
  content: "\e055";
}

.elg_icon_menu-square_alt:before {
  content: "\e056";
}

.elg_icon_menu-circle_alt:before {
  content: "\e057";
}

.elg_icon_document:before {
  content: "\e058";
}

.elg_icon_documents:before {
  content: "\e059";
}

.elg_icon_pencil_alt:before {
  content: "\e05a";
}

.elg_icon_folder:before {
  content: "\e05b";
}

.elg_icon_folder-open:before {
  content: "\e05c";
}

.elg_icon_folder-add:before {
  content: "\e05d";
}

.elg_icon_folder_upload:before {
  content: "\e05e";
}

.elg_icon_folder_download:before {
  content: "\e05f";
}

.elg_icon_info:before {
  content: "\e060";
}

.elg_icon_error-circle:before {
  content: "\e061";
}

.elg_icon_error-oct:before {
  content: "\e062";
}

.elg_icon_error-triangle:before {
  content: "\e063";
}

.elg_icon_question_alt:before {
  content: "\e064";
}

.elg_icon_comment:before {
  content: "\e065";
}

.elg_icon_chat:before {
  content: "\e066";
}

.elg_icon_vol-mute:before {
  content: "\e067";
}

.elg_icon_volume-low:before {
  content: "\e068";
}

.elg_icon_volume-high:before {
  content: "\e069";
}

.elg_icon_quotations_alt:before {
  content: "\e06a";
}

.elg_icon_clock:before {
  content: "\e06b";
}

.elg_icon_lock:before {
  content: "\e06c";
}

.elg_icon_lock-open:before {
  content: "\e06d";
}

.elg_icon_key:before {
  content: "\e06e";
}

.elg_icon_cloud:before {
  content: "\e06f";
}

.elg_icon_cloud-upload:before {
  content: "\e070";
}

.elg_icon_cloud-download:before {
  content: "\e071";
}

.elg_icon_lightbulb:before {
  content: "\e072";
}

.elg_icon_gift:before {
  content: "\e073";
}

.elg_icon_house:before {
  content: "\e074";
}

.elg_icon_camera:before {
  content: "\e075";
}

.elg_icon_mail:before {
  content: "\e076";
}

.elg_icon_cone:before {
  content: "\e077";
}

.elg_icon_ribbon:before {
  content: "\e078";
}

.elg_icon_bag:before {
  content: "\e079";
}

.elg_icon_cart:before {
  content: "\e07a";
}

.elg_icon_tag:before {
  content: "\e07b";
}

.elg_icon_tags:before {
  content: "\e07c";
}

.elg_icon_trash:before {
  content: "\e07d";
}

.elg_icon_cursor:before {
  content: "\e07e";
}

.elg_icon_mic:before {
  content: "\e07f";
}

.elg_icon_compass:before {
  content: "\e080";
}

.elg_icon_pin:before {
  content: "\e081";
}

.elg_icon_pushpin:before {
  content: "\e082";
}

.elg_icon_map:before {
  content: "\e083";
}

.elg_icon_drawer:before {
  content: "\e084";
}

.elg_icon_toolbox:before {
  content: "\e085";
}

.elg_icon_book:before {
  content: "\e086";
}

.elg_icon_contacts:before {
  content: "\e087";
}

.elg_icon_archive:before {
  content: "\e088";
}

.elg_icon_heart:before {
  content: "\e089";
}

.elg_icon_profile:before {
  content: "\e08a";
}

.elg_icon_group:before {
  content: "\e08b";
}

.elg_icon_grid-2x2:before {
  content: "\e08c";
}

.elg_icon_grid-3x3:before {
  content: "\e08d";
}

.elg_icon_music:before {
  content: "\e08e";
}

.elg_icon_pause_alt:before {
  content: "\e08f";
}

.elg_icon_phone:before {
  content: "\e090";
}

.elg_icon_upload:before {
  content: "\e091";
}

.elg_icon_download:before {
  content: "\e092";
}

.elg_social_facebook:before {
  content: "\e093";
}

.elg_social_twitter:before {
  content: "\e094";
}

.elg_social_pinterest:before {
  content: "\e095";
}

.elg_social_googleplus:before {
  content: "\e096";
}

.elg_social_tumblr:before {
  content: "\e097";
}

.elg_social_tumbleupon:before {
  content: "\e098";
}

.elg_social_wordpress:before {
  content: "\e099";
}

.elg_social_instagram:before {
  content: "\e09a";
}

.elg_social_dribbble:before {
  content: "\e09b";
}

.elg_social_vimeo:before {
  content: "\e09c";
}

.elg_social_linkedin:before {
  content: "\e09d";
}

.elg_social_rss:before {
  content: "\e09e";
}

.elg_social_deviantart:before {
  content: "\e09f";
}

.elg_social_share:before {
  content: "\e0a0";
}

.elg_social_myspace:before {
  content: "\e0a1";
}

.elg_social_skype:before {
  content: "\e0a2";
}

.elg_social_youtube:before {
  content: "\e0a3";
}

.elg_social_picassa:before {
  content: "\e0a4";
}

.elg_social_googledrive:before {
  content: "\e0a5";
}

.elg_social_flickr:before {
  content: "\e0a6";
}

.elg_social_blogger:before {
  content: "\e0a7";
}

.elg_social_spotify:before {
  content: "\e0a8";
}

.elg_social_delicious:before {
  content: "\e0a9";
}

.elg_social_facebook_circle:before {
  content: "\e0aa";
}

.elg_social_twitter_circle:before {
  content: "\e0ab";
}

.elg_social_pinterest_circle:before {
  content: "\e0ac";
}

.elg_social_googleplus_circle:before {
  content: "\e0ad";
}

.elg_social_tumblr_circle:before {
  content: "\e0ae";
}

.elg_social_stumbleupon_circle:before {
  content: "\e0af";
}

.elg_social_wordpress_circle:before {
  content: "\e0b0";
}

.elg_social_instagram_circle:before {
  content: "\e0b1";
}

.elg_social_dribbble_circle:before {
  content: "\e0b2";
}

.elg_social_vimeo_circle:before {
  content: "\e0b3";
}

.elg_social_linkedin_circle:before {
  content: "\e0b4";
}

.elg_social_rss_circle:before {
  content: "\e0b5";
}

.elg_social_deviantart_circle:before {
  content: "\e0b6";
}

.elg_social_share_circle:before {
  content: "\e0b7";
}

.elg_social_myspace_circle:before {
  content: "\e0b8";
}

.elg_social_skype_circle:before {
  content: "\e0b9";
}

.elg_social_youtube_circle:before {
  content: "\e0ba";
}

.elg_social_picassa_circle:before {
  content: "\e0bb";
}

.elg_social_googledrive_alt2:before {
  content: "\e0bc";
}

.elg_social_flickr_circle:before {
  content: "\e0bd";
}

.elg_social_blogger_circle:before {
  content: "\e0be";
}

.elg_social_spotify_circle:before {
  content: "\e0bf";
}

.elg_social_delicious_circle:before {
  content: "\e0c0";
}

.elg_social_facebook_square:before {
  content: "\e0c1";
}

.elg_social_twitter_square:before {
  content: "\e0c2";
}

.elg_social_pinterest_square:before {
  content: "\e0c3";
}

.elg_social_googleplus_square:before {
  content: "\e0c4";
}

.elg_social_tumblr_square:before {
  content: "\e0c5";
}

.elg_social_stumbleupon_square:before {
  content: "\e0c6";
}

.elg_social_wordpress_square:before {
  content: "\e0c7";
}

.elg_social_instagram_square:before {
  content: "\e0c8";
}

.elg_social_dribbble_square:before {
  content: "\e0c9";
}

.elg_social_vimeo_square:before {
  content: "\e0ca";
}

.elg_social_linkedin_square:before {
  content: "\e0cb";
}

.elg_social_rss_square:before {
  content: "\e0cc";
}

.elg_social_deviantart_square:before {
  content: "\e0cd";
}

.elg_social_share_square:before {
  content: "\e0ce";
}

.elg_social_myspace_square:before {
  content: "\e0cf";
}

.elg_social_skype_square:before {
  content: "\e0d0";
}

.elg_social_youtube_square:before {
  content: "\e0d1";
}

.elg_social_picassa_square:before {
  content: "\e0d2";
}

.elg_social_googledrive_square:before {
  content: "\e0d3";
}

.elg_social_flickr_square:before {
  content: "\e0d4";
}

.elg_social_blogger_square:before {
  content: "\e0d5";
}

.elg_social_spotify_square:before {
  content: "\e0d6";
}

.elg_social_delicious_square:before {
  content: "\e0d7";
}

.elg_icon_printer:before {
  content: "\e103";
}

.elg_icon_calulator:before {
  content: "\e0ee";
}

.elg_icon_building:before {
  content: "\e0ef";
}

.elg_icon_floppy:before {
  content: "\e0e8";
}

.elg_icon_drive:before {
  content: "\e0ea";
}

.elg_icon_search-2:before {
  content: "\e101";
}

.elg_icon_id:before {
  content: "\e107";
}

.elg_icon_id-2:before {
  content: "\e108";
}

.elg_icon_puzzle:before {
  content: "\e102";
}

.elg_icon_like:before {
  content: "\e106";
}

.elg_icon_dislike:before {
  content: "\e0eb";
}

.elg_icon_mug:before {
  content: "\e105";
}

.elg_icon_currency:before {
  content: "\e0ed";
}

.elg_icon_wallet:before {
  content: "\e100";
}

.elg_icon_pens:before {
  content: "\e104";
}

.elg_icon_easel:before {
  content: "\e0e9";
}

.elg_icon_flowchart:before {
  content: "\e109";
}

.elg_icon_datareport:before {
  content: "\e0ec";
}

.elg_icon_briefcase:before {
  content: "\e0fe";
}

.elg_icon_shield:before {
  content: "\e0f6";
}

.elg_icon_percent:before {
  content: "\e0fb";
}

.elg_icon_globe:before {
  content: "\e0e2";
}

.elg_icon_globe-2:before {
  content: "\e0e3";
}

.elg_icon_target:before {
  content: "\e0f5";
}

.elg_icon_hourglass:before {
  content: "\e0e1";
}

.elg_icon_balance:before {
  content: "\e0ff";
}

.elg_icon_rook:before {
  content: "\e0f8";
}

.elg_icon_printer-alt:before {
  content: "\e0fa";
}

.elg_icon_calculator_alt:before {
  content: "\e0e7";
}

.elg_icon_building_alt:before {
  content: "\e0fd";
}

.elg_icon_floppy_alt:before {
  content: "\e0e4";
}

.elg_icon_drive_alt:before {
  content: "\e0e5";
}

.elg_icon_search_alt:before {
  content: "\e0f7";
}

.elg_icon_id_alt:before {
  content: "\e0e0";
}

.elg_icon_id-2_alt:before {
  content: "\e0fc";
}

.elg_icon_puzzle_alt:before {
  content: "\e0f9";
}

.elg_icon_like_alt:before {
  content: "\e0dd";
}

.elg_icon_dislike_alt:before {
  content: "\e0f1";
}

.elg_icon_mug_alt:before {
  content: "\e0dc";
}

.elg_icon_currency_alt:before {
  content: "\e0f3";
}

.elg_icon_wallet_alt:before {
  content: "\e0d8";
}

.elg_icon_pens_alt:before {
  content: "\e0db";
}

.elg_icon_easel_alt:before {
  content: "\e0f0";
}

.elg_icon_flowchart_alt:before {
  content: "\e0df";
}

.elg_icon_datareport_alt:before {
  content: "\e0f2";
}

.elg_icon_briefcase_alt:before {
  content: "\e0f4";
}

.elg_icon_shield_alt:before {
  content: "\e0d9";
}

.elg_icon_percent_alt:before {
  content: "\e0da";
}

.elg_icon_globe_alt:before {
  content: "\e0de";
}

.elg_icon_clipboard:before {
  content: "\e0e6";
}

.glyph {
  float: left;
  text-align: center;
  padding: .75em;
  margin: .4em 1.5em .75em 0;
  width: 6em;
  text-shadow: none;
}

.glyph_big {
  font-size: 128px;
  color: #59c5dc;
  float: left;
  margin-right: 20px;
}

.glyph div {
  padding-bottom: 10px;
}

.glyph input {
  font-family: consolas, monospace;
  font-size: 12px;
  width: 100%;
  text-align: center;
  border: 0;
  box-shadow: 0 0 0 1px #ccc;
  padding: .2em;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

.centered {
  margin-left: auto;
  margin-right: auto;
}

.glyph .fs1 {
  font-size: 2em;
}


