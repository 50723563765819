.is-logo-sl {
  .logo.scroll {
    display: none;
  }
}

.header-menu {
  z-index: 9999;
  position: relative;
  > .container {
    position: relative;
  }
  #plazart-mainnav {
    .navbar-header {
      > .btn-navbar {
        display: none;
        padding: 45px 15px 40px 0;
        margin: auto;
        border: none;
        float: none;
        cursor: pointer;
        i {
          color: @white;
        }
      }
    }
    .img_pane.hikashop_product {
      padding: 0 !important;
    }
  }
  .navbar {
    border: none;
    min-height: 0;
  }
  .plazart-megamenu {
    .hikashop_products li {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
    .hikashop_container {
      border: none !important;
    }
    > ul {
      float: right;
      > li {
        display: block !important;
        cursor: pointer;
      }
    }
    > ul > li > a {
      text-transform: uppercase;
      padding: 33px 23px;
      font-family: "Montserrat", sans-serif;
      font-weight: normal;
      font-size: 13px;
    }
    li a {
      font-size: 14px;
      color: @crt_title_cl;
      &:hover {
        color: @crt_global_cl;
      }
    }
    .mega-align-justify {
      .mega-nav {
        > li:first-child > a {
          border-top: 1px solid #ededed;
        }
      }
    }
    .dropdown-menu {
      border-top: 3px solid @crt_title_cl;
      -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      padding: 0;
      .mega-col-nav.col-md-12 {
        padding: 0;
        .mega-inner {
          padding: 0;
        }
      }
      li {
        &.active,
        &.current {
          > a {
            color: @crt_global_cl !important;
          }
        }
        a {
          &:after {
            border: none;
          }
        }
      }
      .row > .mega-nav {
        > li {
          &:last-child {
            > a {
              border: none;
            }
          }
        }
      }
      li {
        padding: 0;
        &.open, &:hover, &:focus, &:active {
          > a {
            background: #eee;
          }
        }
        > a {
          padding: 17px 30px !important;
          border-bottom: 1px solid #ededed;
          color: @color_666 !important;

        }
      }
      &.col-xs-12 {
        padding-bottom: 25px;
        .mega-dropdown-inner {
          padding-left: 15px;
          padding-right: 15px;
        }
        .mega-inner {
          padding: 0;
        }
        .mega-group-ct .row {
          margin: auto;
        }
        .mega-group {
          .mega-dropdown-inner {
            padding: 0;
            li {
              &.open > a, &.open > a:focus, &.open > a:hover, > a:focus, > a:hover, > a:active, &.open > a:active {
                color: @crt_global_cl !important;
              }
              a {
                background: transparent !important;
              }
              &:last-child {
                a {
                  border: none;
                }
              }
            }
          }
        }
        .mega-col-nav {
          li > a {
            padding: 15px 0 !important;
            &.mega-group-title {
              background: transparent !important;
              border: none;
              color: @crt_title_cl !important;
              font-weight: 400;
              line-height: 24px;
              padding: 36px 0 26px !important;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }

  .menu-style-1 {
    position: static;
    .nav-collapse {
      padding: 0;
    }
    .plazart-mainnav {
      margin: 0;
      position: static;
    }
    .plazart-megamenu {
      .level0 {
        > li {
          padding: 0;
          &.open > a, &.open > a:focus, &.open > a:hover, > a:focus, > a:hover, > a:active, &.open > a:active {
            background: transparent;
          }
          &.open,
          &.current {
            > a {
              color: @crt_global_cl;
            }
          }
          > a {
            padding: 43px 27px;
          }
        }
      }
    }
  }
  .menu-style-3 {
    position: static;
    .nav-collapse {
      padding: 0;
    }
    #plazart-mainnav {
      margin: 0;
      position: static;
    }
    .plazart-megamenu {
      ul {
        &.level0 > li {
          padding: 0;
          &.active {
            > a {
              background: transparent !important;
            }
          }
          > a {
            padding: 43px 28px;
            color: @crt_title_cl !important;
            -webkit-transition: background 200ms linear 0s, color 200ms linear 0s;
            -moz-transition: background 200ms linear 0s, color 200ms linear 0s;
            -ms-transition: background 200ms linear 0s, color 200ms linear 0s;
            -o-transition: background 200ms linear 0s, color 200ms linear 0s;
            transition: background 200ms linear 0s, color 200ms linear 0s;
          }
        }
        li {
          &.current,
          &.active {
            > a {
              color: @crt_global_cl !important;
            }
          }
          a {
            cursor: pointer;
          }
        }
      }
    }
  }

}

.custom_animated {
  .plazart-megamenu {
    ul > li.open {
      > .dropdown-menu {
        top: 100% !important;
        opacity: 1;
        visibility: visible;
      }
      &.dropdown-submenu {
        &.mega-align-right {
          .dropdown-menu {
            right: auto !important;
            left: -100% !important;
          }
        }
        &.mega-align-left {
          .dropdown-menu {
            left: auto !important;
            right: -100% !important;
          }
        }
        .dropdown-menu {
          top: -3px !important;

        }
      }
    }
  }
  .dropdown-menu {
    top: 120% !important;
    opacity: 0;
    visibility: hidden;
    //display: block !important;
    .transition(all 0.3s ease-in-out 0s);

  }
  .dropdown-submenu {
    &.mega-align-right {
      .dropdown-menu {
        right: auto !important;
        left: -120% !important;
      }
    }
    &.mega-align-left {
      .dropdown-menu {
        left: auto !important;
        right: -120% !important;
      }
    }
    .dropdown-menu {
      margin: 0;
      .transition(all 0.5s ease-in-out 0s);
      top: -3px !important;

    }
  }
  .plazart-megamenu .dropdown .dropdown-menu {
    display: block;
  }
}

.header1 {
  .is-logo-sl {
    padding: 40px 0;
  }
  > .header-menu {
    display: block;
    //width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 22px 0 0;
    background: rgba(0, 0, 0, 0.5);
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.44) 12%, rgba(0, 0, 0, 0.25) 50%, rgba(0, 0, 0, 0.14) 73%, rgba(0, 0, 0, 0) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.5)), color-stop(12%, rgba(0, 0, 0, 0.44)), color-stop(50%, rgba(0, 0, 0, 0.25)), color-stop(73%, rgba(0, 0, 0, 0.14)), color-stop(100%, rgba(0, 0, 0, 0)));
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.44) 12%, rgba(0, 0, 0, 0.25) 50%, rgba(0, 0, 0, 0.14) 73%, rgba(0, 0, 0, 0) 100%);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.44) 12%, rgba(0, 0, 0, 0.25) 50%, rgba(0, 0, 0, 0.14) 73%, rgba(0, 0, 0, 0) 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.44) 12%, rgba(0, 0, 0, 0.25) 50%, rgba(0, 0, 0, 0.14) 73%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.44) 12%, rgba(0, 0, 0, 0.25) 50%, rgba(0, 0, 0, 0.14) 73%, rgba(0, 0, 0, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', GradientType=0);
    .plazart-megamenu {
      > ul > li > a {
        color: #FFF;
        &:hover {
          color: @crt_global_cl;
        }
      }
      .nav > .dropdown > .dropdown-toggle .caret {
        display: none;
      }
    }
  }
}

.caret {
  display: none !important;
}

.header2 {
  .is-logo-sl {
    padding: 37px 0;
  }
  .header-menu #plazart-mainnav .navbar-header > .btn-navbar i {
    color: #bebebe;
  }

  .header-menu {
    .plazart-megamenu {
      > ul {
        > li {
          &.active a, &.open a, a:hover, a:focus {
            color: @crt_global_cl;
            &:after {
              .scale(1);
              opacity: 1;
              visibility: visible;
              top: 0;
            }
          }
          > a {
            text-transform: none;
            font-size: 15px;
            &:after {
              content: '';
              display: block;
              position: absolute;
              top: 5px;
              border-top: 2px solid @crt_global_cl;
              left: 0;
              right: 0;
              .transition(all 0.3s ease-in-out 0s);
              .scale(0.6);
              visibility: hidden;
              opacity: 0;
            }
          }
        }
      }
    }
  }
  .k2SearchBlock .tz_icon_search, .hikashop_cart_module .tz_icon_cart {
    i {
      color: #bebebe;
    }
    &:hover {
      i {
        color: @crt_global_cl;
      }
    }
  }
  .top-bar {
    > .container > .row {
      border-bottom: 1px solid #e3e3e3;
      margin: 0;
      > div {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.header3 {
  .tzheader_social li a,
  .tzheader_site,
  .mod-languages .tz_language {
    color: #ffffff;
  }
  .tzheader_social li a:hover {
    color: @crt_title_cl;
  }
  .is-logo-sl {
    padding: 37px 0;
  }
  .k2SearchBlock .tz_icon_search i, .hikashop_cart_module .tz_icon_cart i {
    color: #bebebe;
  }

}

.tzheader_site {
  font-size: 13px;
  font-weight: 300;
  line-height: 13px;
  padding: 16px 0;
  color: #333333;
  i {
    font-size: 14px;
    padding-right: 10px;
  }
}

.tzheader_line {
  font-size: 14px;
  padding: 0 15px;
  color: #e3e3e3;
}

.width-auto {
  width: auto;
}

.padding-lr-0 {
  padding-left: 0;
  padding-right: 0;
}

.padding-lr-0-15 {
  padding-left: 0;
  padding-right: 15px;
}

div[data-class='no-pading-l'] {
  padding-left: 0;
}

/*Slider*/
.slide-home-3 {
  .rev_slider_wrapper .tp-leftarrow.preview1 {
    left: 30px !important;
  }
  .rev_slider_wrapper .tp-rightarrow.preview1 {
    right: 30px !important;
  }
}

.tp-dottedoverlay.twoxtwo:after {

  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-height: 130px;
}

div.tp-dottedoverlay.twoxtwo {
  background-image: none;
  background: rgba(0, 0, 0, 0.4);
}

div.tparrows.preview1 {
  height: 72px;
  width: 72px;
  background: #000;
  .tp-arr-imgholder {
    width: 108px;
  }
}

div.tparrows.preview1:after {
  line-height: 72px;
  height: 72px;
  width: 72px;
}

/*end Slider*/

/* Bg Rules*/
.bg-rules {
  position: relative;
  &:after {
    content: '';
    background: url("../../../images/ruler.png") repeat-x;
    height: 12px;
    left: 0;
    width: 100%;
    position: absolute;
    bottom: -12px;
  }
}

/* End Bg Rules*/

.bg-pattern {
  position: relative;
  &:before {
    content: '';
    background: url("../../../images/pattern.png") repeat rgba(0, 0, 0, 0.43);
    top: 0;
    right: 0;
    left: 0;
    position: absolute;
    bottom: 0;
  }
}

/*Footer*/
.footer-2 {
  .module-title span {
    font-weight: 500;
    letter-spacing: 1px;
  }
}

/* Clear Both*/
@media (max-width: 991px) {
  .clear_both_sm {
    display: block;
    clear: both;
  }
}