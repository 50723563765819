.rev_slider_wrapper {
  .tparrows.preview1 {
    &:hover:after{
      background: @crt_global_cl;
      color: @black;
    }
    &:after {
      font-family: FontAwesome;
    }
  }

  .tp-leftarrow.preview1::after {
    content: '\f053';
  }

  .tp-rightarrow.preview1::after {
    content: '\f054'
  }
}