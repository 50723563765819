body {
  color: @color_666;
  font-size: 14px;
  overflow-x: hidden;
}

p {
  font-weight: 300;
  letter-spacing: 0.5px;
  line-height: 25px;
  font-size: 100%;
}

a, a:focus, a:hover {
  color: @crt_title_cl;
  cursor: pointer;
  outline: none;
  text-decoration: none;
}

.bg_white {
  background: @white;
}

.gl-color {
  color: @crt_global_cl;
}

.page_heading {
  color: @white;
}

h1, h2, h3, h4, h5, h6 {
  color: @crt_title_cl;
  font-weight: 400;
  padding: 0;
  margin: 0 0 20px;
}

h1 {
  font-size: 35px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 23px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 15px;
}

h6 {
  font-size: 14px;
}

ul {
  list-style: none;
  padding: 0;
  font-weight: lighter;
  li {
    padding: 8px 0;
  }
}

.btn {
  background: #e5ae49;
  border: none;
  color: @white;
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
  line-height: 25px;
  padding: 8px 27px;
  text-decoration: none;
  .transition(0.25s);
  .border-radius(3px);
  &:hover {
    background: @crt_title_cl;
    color: @white;
  }
  &.primary {
    border: 1px solid #337ab7;
    color: #337ab7;
    background: transparent;
    &:hover {
      background: #337ab7;
      color: @white;
    }
    &.bg {
      background: #337ab7;
      color: @white;
      &:hover {
        background: @white;
        color: #337ab7;
      }
    }
  }
  &.success {
    border: 1px solid #5cb85c;
    color: #5cb85c;
    background: transparent;
    &:hover {
      background: #5cb85c;
      color: @white;
    }
    &.bg {
      background: #5cb85c;
      color: @white;
      &:hover {
        background: @white;
        color: #5cb85c;
      }
    }
  }
  &.info {
    border: 1px solid #5bc0de;
    color: #5bc0de;
    background: transparent;
    &:hover {
      background: #5bc0de;
      color: @white;
    }
    &.bg {
      background: #5bc0de;
      color: @white;
      &:hover {
        background: @white;
        color: #5bc0de;
      }
    }
  }
  &.warning {
    border: 1px solid #ffad9e;
    color: #ffad9e;
    background: transparent;
    &:hover {
      background: #ffad9e;
      color: @white;
    }
    &.bg {
      background: #ffad9e;
      color: @white;
      &:hover {
        background: @white;
        color: #ffad9e;
      }
    }
  }
  &.danger {
    border: 1px solid #d9534f;
    background: transparent;
    color: #d9534f;
    &:hover {
      background: #d9534f;
      color: @white;
    }
    &.bg {
      background: #d9534f;
      color: @white;
      &:hover {
        background: @white;
        color: #d9534f;
      }
    }
  }
  &.link {
    border: 1px solid @color_666;
    background: transparent;
    color: @color_666;
    &:hover {
      background: @color_666;
      color: @white;
    }
    &.bg {
      background: @color_666;
      color: @white;
      &:hover {
        background: @white;
        color: @color_666;
      }
    }
  }
  &.no-radius {
    .border-radius(0);
  }
}

img {
  max-width: 100%;
}

.medium p {
  font-weight: 400;
}

.dropcap-type1 {
  color: #e5ae49;
  display: inline-block;
  float: left;
  font-size: 75px;
  font-weight: 500;
  line-height: 75px;
  margin: 0 7px 0 0;
}

.dropcap-type2 {
  background-color: #e5ae49;
  border-radius: 3px;
  color: @white;
  display: inline-block;
  float: left;
  font-size: 45px;
  font-weight: 400;
  line-height: 45px;
  margin: 8px 7px 0 0;
  padding: 7px 11px;
}

.typo_button {
  display: inline-block;
}

.btn-typo {
  padding-bottom: 90px;
}

.btn-typo {
  .typo_button {
    margin-bottom: 22px;
    padding: 0 20px 10px 0;
  }
}

.box-shadow-bottom {
  box-shadow: 0 -1px 3px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 -1px 3px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 -1px 3px 2px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 -1px 3px 2px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 -1px 3px 2px rgba(0, 0, 0, 0.1);
}

.margin-top-60 {
  margin-top: 60px;
}

.tp-caption .btn,
.btn {
  &.btn-style-1 {
    background: @crt_global_cl;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 0px, rgba(255, 255, 255, 0.01) 100%);
    background-repeat: repeat-x;
    &:hover {
      background: @white;
      color: @crt_global_cl;
      border-color: @crt_global_cl;
    }
  }
  &.btn-style-2 {
    background: transparent;
    color: @crt_global_cl;
    &:hover {
      color: @white;
      background: @crt_global_cl;
    }
  }
  &.size-slide-1 {
    font-size: 12px;
    padding: 8px 25px;
    line-height: 2em;
  }
  &.size-slide-3 {
    font-size: 12px;
    padding: 8px 15px;
    line-height: 2em;
    border-radius: 0;
    background: transparent;
  }
  &.size-slide-2 {
    font-size: 14px;
    padding: 8px 25px;
    line-height: 24px;
    font-weight: 700;
  }
  &.size-lg {
    font-size: 16px;
    padding: 18px 25px;
  }
  &.size-sm {
    font-size: 12px;
    padding: 11px 16px;
  }
  &.size-mini {
    font-size: 11px;
    padding: 8px 12px;
  }
  &.btn-style-3 {
    background: @crt_title_cl;
    border: none;
    font-weight: 300;
    text-transform: none;
    color: @white;
    &:hover {
      background: @crt_global_cl;
      color: @white;
    }
  }
  &.btn-style-4 {
    background: @crt_global_cl;
    &:hover {
      background: @white;
      color: @crt_global_cl;
      border-color: @crt_global_cl;
    }
  }
  color: @white;
  text-align: center;
  .transition(all 0.2s ease-in-out 0s);
  margin: 0;
  padding: 12px 32px;
  font-size: 13px;
  border: 2px solid @crt_global_cl;
  display: inline-block;
  letter-spacing: 2px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1.3em;;
}

.tz-plazart-list {
  &.text-underline {
    li {
      border-bottom: 1px dashed #dbd9d9;
      padding: 15px 0;
    }
  }
  li {
    line-height: 24px;
    i {
      margin-right: 12px;
      color: @crt_global_cl;
    }
  }
  &.tzList-icon_highlight {
    li {
      display: inline-block;
      padding: 11px 0;
      font-weight: 300;
      color: @color_666;
      font-size: 14px;
      line-height: 24px;
      margin: 0;
      &.width-50 {
        width: 49%;
      }
      i {
        background: @crt_global_cl;
        border-radius: 50%;
        color: @white;
        font-size: 12px;
        height: 16px;
        line-height: 15px;
        text-align: center;
        width: 16px;
      }
    }
  }
}

.character-mapping {
  li {
    display: inline-block;
    width: 109px;
    text-align: center;
    margin: 0 30px 20px 0;
    padding: 0;
    i {
      font-size: 32px;
      padding: 10px 0;
      display: block;
    }
    input {
      border: 1px solid #d8e0e5;
      border-radius: 5px;
      display: block;
      font-size: 12px;
      line-height: 12px;
      margin: 0;
      outline: 0 none;
      padding: 5px 0;
      text-align: center;
      width: 100%;
    }
  }
}

.box1 {
  display: inline-block;
  font-size: 16px;
  margin: 20px 10px 0 0;
  padding: 0;
  width: 23%;
  * {
    display: inline-block;
  }
  p {
    font-size: 14px;
  }
}

.et-examples .box1 {
  border: 1px solid #e5e5e5;
  display: block;
  float: left;
  font-size: 13px;
  margin: -1px 0 0 -1px;
  padding: 0;
  width: 25%;
  i {
    border-right: 1px solid #f1f1f1;
    display: inline-block;
    font-size: 32px;
    line-height: 70px;
    margin-right: 5px;
    min-height: 70px;
    min-width: 70px;
    text-align: center;
  }
}

blockquote {
  border-left: 5px solid #eee;
  font-size: 14px;
  margin-top: 20px;
  padding: 15px 20px;
  line-height: 23px;
}

[data-toggle="tooltip"] {
  display: inline-block !important;
  //top: 0 !important;
  //left: 0 !important;
}

.tooltip {
  .tooltip-inner {
    background: @crt_global_cl;
    min-width: 70px;
  }
  &.top {
    .tooltip-arrow {
      border-top-color: @crt_global_cl !important;
    }
  }
  &.left {
    .tooltip-arrow {
      border-left-color: @crt_global_cl !important;
    }
  }
  &.bottom {
    .tooltip-arrow {
      border-bottom-color: @crt_global_cl !important;
    }
  }
  &.right {
    .tooltip-arrow {
      border-right-color: @crt_global_cl !important;
    }
  }
}

.chzn-results {
  .result-selected, .highlighted {
    background: @crt_global_cl !important;
    color: @white !important;
  }
}